import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../redux/features/userSlice";
import Layout from "../components/Layout/Layout";
import axios from "axios";
import getUserData from "../utils/userDataService";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import "./ProductInfo.css";

const ProductInfo = () => {
  const { user } = useSelector((state) => state.user);
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [playerCheck, setPlayerCheck] = useState(null);
  const [product, setProduct] = useState(0);
  const [showImage, setShowImage] = useState(0);
  const [error, setError] = useState(false);
  const [orderId, setOrderId] = useState(false);
  const [mode, setMode] = useState("UPI");
  const [balance, setBalance] = useState("");
  const [loading, setLoading] = useState(false);
  const [orderLoader, setOrderLoader] = useState(false);
  const [orderPopup, setOrderPopup] = useState(false);

  useEffect(() => {
    getUserData(dispatch, setUser, setBalance);
  }, []);

  //!NEW STATE
  const [amount, setAmount] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [productId, setProductId] = useState("");
  //! API BASED
  const [userId, setUserId] = useState("");
  const [zoneId, setZoneId] = useState("");

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, []);

  function setPriceAndId(amount) {
    if (user?.reseller === "yes") {
      const price = product?.cost?.find(
        (item) => item.amount === amount
      )?.resPrice;
      setSelectedPrice(price);
      const id = product?.cost?.find((item) => item.amount === amount)?.id;
      setProductId(id);
    } else {
      const price = product?.cost?.find(
        (item) => item.amount === amount
      )?.price;
      setSelectedPrice(price);
      const id = product?.cost?.find((item) => item.amount === amount)?.id;
      setProductId(id);
    }
  }

  const getProduct = async () => {
    try {
      const res = await axios.post("/api/product/get-product-by-name", {
        name: params.name,
      });
      if (res.data.success) {
        setProduct(res.data.data);
        const defaultAmount = res.data.data?.cost?.[0]?.amount;
        const defaultPrice =
          user && user?.reseller === "yes"
            ? res.data.data?.cost?.[0]?.resPrice
            : res.data.data?.cost?.[0]?.price;
        const defaultId = res.data.data?.cost?.[0]?.id;
        // setAmount(defaultAmount);
        // setSelectedPrice(defaultPrice);
        // setProductId(defaultId);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const generateOrderId = () => {
    const now = new Date();
    const year = now.getFullYear().toString().slice(-2); // Last two digits of the year
    const month = (now.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
    const day = now.getDate().toString().padStart(2, "0");
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    const seconds = now.getSeconds().toString().padStart(2, "0");
    const randomNum = Math.floor(1000 + Math.random() * 9000); // Ensures a 4-digit number
    const orderId = `${year}${month}${day}${hours}${minutes}${seconds}${randomNum}`;

    setOrderId(orderId);
  };

  useEffect(() => {
    getProduct();
  }, [params?.name]);

  useEffect(() => {
    generateOrderId();
  }, []);

  async function handleCheckPlayer() {
    if (product?.playerCheck === "yes") {
      if (userId === "") {
        return message.error("Enter ID");
      }
      if (zoneId === "") {
        return message.error("Enter Zone ID");
      }
    }
    try {
      // setLoading(true);
      const object = {
        userid: userId,
        zoneid: zoneId,
        apiName: product?.apiName,
      };
      const res = await axios.post("/api/payment/get-role", object);
      if (res.data.success) {
        setPlayerCheck(res.data.username);
        setLoading(false);
      } else {
        message.error(res.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  function checkPlaceOrder(e, mode) {
    if (product?.playerCheck === "yes") {
      if (playerCheck === null) {
        return message.error("Check username");
      }
    }

    if (product?.fields === "2") {
      if (userId === "") {
        return message.error(`Enter ${product?.tagOne}`);
      }
      if (zoneId === "") {
        return message.error(`Enter ${product?.tagTwo}`);
      }
    } else if (product?.fields === "1") {
      if (userId === "") {
        return message.error(`Enter ${product?.tagOne}`);
      }
    }

    if (product?.api === "yes") {
      if (mode === "UPI") {
        handleSmileOneUpiOrder(e);
      } else {
        handleSmileOneWalletOrder(e);
      }
    } else {
      if (mode === "UPI") {
        handleUpiOrder(e);
      } else {
        handleWalletOrder(e);
      }
    }
  }

  // smile
  const handleSmileOneUpiOrder = async (e) => {
    e.preventDefault();
    try {
      setOrderLoader(true);
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: product?.region,
        customer_name: user?.fname,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        callback_url: `https://ivystore.in/api/smile/status?orderId=${orderId}`,
        txn_note:
          userId +
          "@" +
          zoneId +
          "@" +
          productId +
          "@" +
          product?.name +
          "@" +
          amount,
      };

      const response = await axios.post("/api/smile/create", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (response.data.success && response.data.data.status) {
        window.location.href = response.data.data.results.payment_url;
      } else {
        message.error(response.data.message);
        setOrderLoader(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleSmileOneWalletOrder = async (e) => {
    try {
      e.preventDefault();
      if (parseInt(balance) < parseInt(selectedPrice)) {
        return message.error("Balance is less for this order");
      }

      const paymentObject = {
        orderId: orderId,
        region: product?.region,
        txn_amount: selectedPrice,
        product_name: product?.name,
        customer_name: user?.fname,
        customer_mobile: user?.mobile,
        customer_email: user?.email,
        txn_note:
          userId +
          "@" +
          zoneId +
          "@" +
          productId +
          "@" +
          product?.name +
          "@" +
          amount,
      };

      setOrderLoader(true);
      const res = await axios.post("/api/smile/wallet", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        navigate("/orders");
      } else {
        setOrderLoader(false);
        message.error(res.data.message);
      }
    } catch (error) {
      setOrderLoader(false);
      console.log(error.message);
    }
  };

  // manaul
  const handleUpiOrder = async (e) => {
    e.preventDefault();
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: product?.name,
        customer_name: user?.fname,
        customer_mobile: user?.mobile,
        customer_email: user?.email,
        callback_url: `https://ivystore.in/api/manual/status?orderId=${orderId}`,
        txn_note: userId + "@" + zoneId + "@" + amount,
      };

      setOrderLoader(true);
      const response = await axios.post("/api/manual/create", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      if (response.data.success && response.data.data.status) {
        window.location.href = response.data.data.results.payment_url;
      } else {
        message.error(response.data.message);
        setOrderLoader(false);
      }
    } catch (error) {
      console.log(error);
      setOrderLoader(false);
    }
  };
  const handleWalletOrder = async (e) => {
    e.preventDefault();
    if (parseInt(balance) < parseInt(selectedPrice)) {
      return message.error("Balance is less for this order");
    }
    try {
      const paymentObject = {
        orderId: orderId,
        txn_amount: selectedPrice,
        product_name: product?.name,
        customer_name: user?.fname,
        customer_mobile: user?.mobile,
        customer_email: user?.email,
        txn_note: userId + "@" + zoneId + "@" + amount,
      };

      setOrderLoader(true);
      const res = await axios.post("/api/manual/wallet", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        navigate("/orders");
      } else {
        message.error(res.data.message);
        setOrderLoader(false);
      }
    } catch (error) {
      console.log(error);
      setOrderLoader(false);
    }
  };

  return (
    <Layout>
      {/* <div className="p-info-container area">
        <ul className="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
        <div className="pro-img">
          {product?.image && (
            <img src={`https://ivystore.in/${product?.image}`} alt="" />
          )}
        </div>
        <div className="pro-content">
          <h2>{product?.name}</h2>
          <h6>⚡Instant Recharge || 24x7 Support⚡</h6>
        </div>
      </div> */}

      {orderLoader ? (
        <div className="order-loader">
          <div class="loaderr">
            <span class="bar"></span>
            <span class="bar"></span>
            <span class="bar"></span>
          </div>
          <div className="text-white mt-2">Processing...</div>
        </div>
      ) : (
        <div className="single-product-container">
          {/* GAME NAME AND IMAGE */}
          {/* GAME NAME AND IMAGE */}
          {/* GAME NAME AND IMAGE */}
          <div className="leftside">
            <div className="image">
              <img src={`https://ivystore.in/${product?.image}`} alt="" />
              <h5>{product?.name}</h5>
            </div>
            <div className="content">
              <p>{product?.desc}</p>
            </div>
          </div>

          {/* ORDER INFORMATION */}
          {/* ORDER INFORMATION */}
          {/* ORDER INFORMATION */}
          <div className="rightside">
            <div className="">
              <h4>Enter Game Details</h4>
              <hr className="text-white" />
              {product?.fields === "1" ? (
                <div className="d-flex align-items-center">
                  <input
                    className="player-tag"
                    type="text"
                    name="userId"
                    placeholder={`${product?.tagOne}`}
                    onChange={(e) => setUserId(e.target.value)}
                    value={userId}
                  />
                </div>
              ) : product?.fields === "2" ? (
                <>
                  <div className="d-flex align-items-center">
                    <input
                      className="player-tag"
                      type="text"
                      name="userId"
                      placeholder={`${product?.tagOne}`}
                      onChange={(e) => setUserId(e.target.value)}
                      value={userId}
                    />
                  </div>
                  <input
                    className="player-tag"
                    type="text"
                    name="zoneid"
                    placeholder={`${product?.tagTwo}`}
                    onChange={(e) => setZoneId(e.target.value)}
                    value={zoneId}
                  />
                  {loading && (
                    <>
                      <div
                        className="spinner-border spinner-border-sm me-2 mt-2 text-white"
                        role="status"
                      >
                        <span class="visually-hidden"></span>
                      </div>
                      <span className="text-white">Checking Username</span>
                    </>
                  )}
                </>
              ) : (
                product?.fields === "3" && (
                  <>
                    <div className="d-flex align-items-center">
                      <input
                        className="player-tag"
                        type="text"
                        name="userId"
                        placeholder={`${product?.tagOne}`}
                        onChange={(e) => setUserId(e.target.value)}
                        value={userId}
                      />
                    </div>
                    <select
                      name="zoneId"
                      className="form-select player-tag text-white"
                      onChange={(e) => setZoneId(e.target.value)}
                    >
                      <option value="">Select Server</option>
                      {product?.tagTwo?.split("+")?.map((item, index) => {
                        return <option value={item}>{item}</option>;
                      })}
                    </select>
                    {loading && (
                      <>
                        <div
                          class="spinner-border spinner-border-sm me-2 mt-2"
                          role="status"
                        >
                          <span class="visually-hidden"></span>
                        </div>
                        Checking Username
                      </>
                    )}
                  </>
                )
              )}
              {playerCheck && (
                <div className="ps-2 playername">
                  {playerCheck && "Username: " + playerCheck}
                  <span
                    onClick={() => {
                      setPlayerCheck(null);
                      setUserId("");
                      setZoneId("");
                    }}
                  >
                    Edit
                  </span>
                </div>
              )}
              {product?.playerCheck === "yes" && playerCheck === null && (
                <button className="p-check-btn" onClick={handleCheckPlayer}>
                  Check Username
                  {loading && (
                    <div className="spinner-grow spinner-grow-sm" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              )}
            </div>
          </div>

          {/* PACKAGES */}
          {/* PACKAGES */}
          {/* PACKAGES */}
          <div className="package-container">
            <h4>Choose the package you want</h4>
            <hr className="text-white" />
            {product?.cost?.map((item, index) => {
              return (
                <div
                  onClick={() => {
                    setAmount(item.amount);
                    setPriceAndId(item.amount);
                    setOrderPopup(true);
                  }}
                  key={index}
                  className={`amount ${amount === item?.amount && "active"}`}
                >
                  <div className="up">
                    <div className="image">
                      <img src={item?.pimg} alt="" />
                    </div>
                    <div className="pack">
                      <p>{item?.packDetails}</p>
                      <p>{item?.amount}</p>
                    </div>
                  </div>
                  <div className="down">
                    <p>
                      ₹{user?.reseller === "yes" ? item?.resPrice : item?.price}
                    </p>
                    <p>₹{item?.fakePrice && item?.fakePrice}</p>
                  </div>
                </div>
              );
            })}
          </div>

          {orderPopup && (
            <div className="order-popup-container">
              <div className="orderPopup">
                <div className="confirmation">
                  <h5 className="m-0 text-white">Purchase Confirmation</h5>
                  <button
                    className="cancelbtn"
                    onClick={() => setOrderPopup(false)}
                  >
                    Cancel
                  </button>
                </div>
                <hr className="text-white" />
                <table className="table table-bordered table-dark">
                  <tbody>
                    <tr>
                      <td>Game</td>
                      <td>{product?.name}</td>
                    </tr>
                    {userId && (
                      <tr>
                        <td>User ID</td>
                        <td>{userId}</td>
                      </tr>
                    )}
                    {zoneId && (
                      <tr>
                        <td>Zone ID</td>
                        <td>{zoneId}</td>
                      </tr>
                    )}
                    {playerCheck && (
                      <tr>
                        <td>In Game Name</td>
                        <td>{playerCheck}</td>
                      </tr>
                    )}
                    <tr>
                      <td>Pack Details</td>
                      <td>{amount}</td>
                    </tr>
                    <tr>
                      <td>Total Amount</td>
                      <td>{selectedPrice}</td>
                    </tr>
                  </tbody>
                </table>
                <hr className="text-white" />
                {user && (
                  <div className="walletbalance">
                    <h6>Wallet Balance</h6>
                    <span className="text-danger">
                      ₹{parseFloat(balance).toFixed(2)}
                    </span>
                  </div>
                )}
                <div className="paybtns">
                  <button onClick={(e) => checkPlaceOrder(e, "WALLET")}>
                    Pay Using Wallet
                  </button>
                  <button onClick={(e) => checkPlaceOrder(e, "UPI")}>
                    Pay Using Upi
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </Layout>
  );
};

export default ProductInfo;
